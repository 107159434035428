
import { DnInput } from '@digitalnatives/form-input';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnInput,
        GwIcon
    },
    props: {
        validator: {
            type: Object,
            default: null
        }
    },
    computed: {
        charactedCount() {
            const value = this.$attrs.value;

            if (value) {
                return value.length;
            } else {
                return 0;
            }
        }
    },
    methods: {
        resetValidation() {
            if (this.validator) {
                /* Reset validation when non-required fields are empty */
                if (this.charactedCount === 0 && !this.validator.required) {
                    this.validator.reset();
                }
            }
        }
    }
};
