

import { get, flatMap } from 'lodash';
import { ValidationObserver } from 'vee-validate';

import getForm from '~/graphql/queries/freeform.graphql';

import FormFields from '~/patterns/molecules/vue-form-fields/vue-form-fields.vue';

export default {
    components: {
        FormFields,
        ValidationObserver
    },
    props: {
        formHandle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            form: {},
            formData: {},
            isSubmitted: false,
            hasErrors: false,
            errors: [],
            successMessages: [],
            isLoading: false
        };
    },
    async fetch() {
        const handle = this.formHandle.toLowerCase(),
            site = 'en',
            { data } = await this.$gql.executeQuery(
                getForm,
                { site, handle }
            );

        this.form = data.freeform.form;
        this.populateFormData();
    },
    computed: {
        pages() {
            return get(this, 'form.pages', []);
        },
        rows() {
            return flatMap(this.pages, page => page.rows || []);
        },
        graphqlMutation() {
            // Map the formData to a string of graphql arguments, where the key is the field handle and the value is the value
            const graphqlArgs = Object.keys(this.formData).map((key) => {
                // Skip any nullish values
                if (!this.formData[key]) {
                    return null;
                }
                return `${key}: "${this.formData[key]}"`;
            }).join(', ');

            return `mutation {
                submission: ${this.form.submissionMutationName} (${graphqlArgs}) {
                    submissionId
                    success
                }
            }`;
        },
    },
    methods: {
        /**
         * Populate the form data based on the form's field handles
         */
        populateFormData() {
            this.form.fields?.forEach((field) => {
                this.formData[field.handle] = field.value;
            });
            this.form.pages.forEach((page) => {
                page.rows.forEach((row) => {
                    row.fields.forEach((field) => {
                        // Don't prefill checkbox or radio fields
                        if (['checkbox', 'radio'].includes(field.type)) {
                            this.formData[field.handle] = null;
                            return;
                        }
                        this.formData[field.handle] = field.value;
                    });
                });
            });
        },
        /**
         * Get the rows for a page, adding a "virtual" row for the submit/next button
         */
        getRows(page) {
            if (page?.rows?.length && page.buttons) {
                // Clone the rows so we don't get an extra button when hydrating.
                const rows = [...page.rows];
                rows.push({
                    fields: [
                        {
                            type: 'submit',
                            handle: 'submit',
                            label: page.buttons.submitLabel,
                            attributes: page.buttons.attributes?.submit
                        }
                    ]
                });
                return rows;
            }
            return page.rows;
        },
        getCmsBaseUrl() {
            return this.$config.cmsUrl.replace('/graphql', '');
        },
        async submitForm() {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            try {
                const { data } = await this.sendToCraft();
                if (!data?.submission?.success) {
                    console.error('Submission failed'); // eslint-disable-line no-console
                    // this.errors = [this.$t('error.title'), this.$t('error.message')];
                } else if (this.form.returnUrl) {
                    this.$router.push(this.form.returnUrl);
                } else {
                    this.successMessages = ['We hebben je bericht ontvangen'];
                    this.$refs.form.reset();
                }
            } catch (e) {
                // this.errors = [e.message];
                console.error(e.message); // eslint-disable-line no-console
            }
            this.isLoading = false;
        },
        async sendToCraft() {
            // Send a gql mutation
            return await this.$gql.executeQuery(
                this.graphqlMutation
            );
        }
    }
};
