
import { ValidationProvider } from 'vee-validate';

import GwRadio from '~/patterns/atoms/radio/radio.vue';
import GwLabel from '~/patterns/atoms/label/label.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        FormField,
        GwRadio,
        GwLabel
    },
    props: {
        field: {
            type: Object,
            required: true
        },

        columns: {
            type: Number,
            required: true
        }
    },
    computed: {
        rules() {
            return this.field.required ? 'required' : null;
        }
    },
    methods: {
        updateChecked(value) {
            this.field.options.forEach((option) => {
                option.checked = option.value === value;
            });
            this.$emit('change', value);
        }
    },
};
