
/** For Hubspot embed code / Form submission storage to work, the form *must* have an input[type=submit], a button[type=submit] will NOT trigger a form submission */
import { get } from 'lodash';
import BaseButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        BaseButton,
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        columns: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dataColumns() {
            return get(this, 'field.attributes.input.0.value');
        }
    }
};
