
import GwSection from '~/patterns/organisms/section/section.vue';
import VueForm from '~/patterns/molecules/vue-form/vue-form.vue';

export default {
    components: {
        GwSection,
        VueForm
    },
    props: {
        form: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    }
};
