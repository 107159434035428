

export default {
    props: {
        field: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            value: ''
        };
    },
    watch: {
        value(val) {
            this.$emit('input', val);
        }
    },
    mounted() {
        this.value = this.field.value;

        if (this.$route.query[this.field.handle]) {
            this.value = this.$route.query[this.field.handle];
        }
    }
};
