
import { ValidationProvider } from 'vee-validate';

import DnInput from '~/patterns/atoms/input/input';
import GwLabel from '~/patterns/atoms/label/label';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import inputMixin from '~/patterns/atoms/freeform/inputMixin.vue';

export default {
    components: {
        ValidationProvider,
        DnInput,
        GwLabel,
        FormField
    },

    mixins: [inputMixin],

    props: {
        field: {
            type: Object,
            required: true
        },
        columns: {
            type: Number,
            required: true
        }
    },
    computed: {
        rules() {
            return this.field.required ? 'required' : null;
        }
    },
    mounted() {
        this.value = this.field.value;
    }
};
