
export default {
    data() {
        return {
            value: ''
        };
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    },
};
