
import { ValidationProvider } from 'vee-validate';
import DnCheckbox from '~/patterns/atoms/checkbox/checkbox';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        DnCheckbox,
        FormField
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            check: false
        };
    },
    computed: {
        rules() {
            return this.field.required ? { required: { allowFalse: false } } : null;
        }
    },
    watch: {
        check() {
            this.$emit('change', this.check);
        }
    }
};
