
import getEditions from '~/graphql/queries/getEditions.graphql';
import getCourses from '~/graphql/queries/getCourses.graphql';
import GwMultiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwLabel from '~/patterns/atoms/label/label.vue';

export default {
    components: {
        GwMultiselect,
        GwIcon,
        GwLabel
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            value: '',
            courses: [],
            selectedCourse: null,
            editions: [],
            filters: {
                site: this.$config.appMultisiteHandle
            }
        };
    },
    watch: {
        selectedCourse(payload) {
            this.filters.course = null;

            if (payload) {
                this.filters.course = payload.id;
            }

            this.getEditions();
            this.$emit('change', payload.id);
        }
    },
    mounted() {
        this.getCourses();
        this.getEditions();
    },
    methods: {
        async getCourses() {
            const { data } = await this.$gql.executeQuery(getCourses, { site: this.$config.appMultisiteHandle });

            this.courses = data.entries;
        },
        async getEditions() {
            const filters = this.filters;

            const { data } = await this.$gql.executeQuery(getEditions, { ...filters });

            this.editions = [...data.entries];
        },
        labelWithInfo({ start, attendance, prices, title }) {
            const date = this.$d(new Date(start), { month: 'numeric', day: 'numeric', year: 'numeric' }),
                formattedDate = start ? `${date} - ` : '';
            const { price } = prices[0],
                formattedPrice = price ? `€${price} H.T. - ` : '';

            const formattedAttendance = attendance ? `${attendance} - ` : '';

            return formattedDate + formattedAttendance + formattedPrice + title;
        }
    }
};
