
import { flatMap } from 'lodash';
import textField from '~/patterns/atoms/freeform/text.vue';
import richtextField from '~/patterns/atoms/freeform/rich-text.vue';
import emailField from '~/patterns/atoms/freeform/email.vue';
import textareaField from '~/patterns/atoms/freeform/textarea.vue';
import radiogroupField from '~/patterns/atoms/freeform/radio-group.vue';
import checkboxgroupField from '~/patterns/atoms/freeform/checkbox-group.vue';
import submitField from '~/patterns/atoms/freeform/submit.vue';
import checkboxField from '~/patterns/atoms/freeform/checkbox.vue';
import fileField from '~/patterns/atoms/freeform/file.vue';
import numberField from '~/patterns/atoms/freeform/number.vue';
import selectField from '~/patterns/atoms/freeform/select.vue';
import editionSelectField from '~/patterns/atoms/freeform/edition-select.vue';
import phoneField from '~/patterns/atoms/freeform/phone.vue';
import hiddenField from '~/patterns/atoms/freeform/hidden.vue';

export default {
    components: {
        textField,
        richtextField,
        radiogroupField,
        checkboxgroupField,
        textareaField,
        emailField,
        submitField,
        checkboxField,
        fileField,
        numberField,
        selectField,
        editionSelectField,
        phoneField,
        hiddenField
    },
    props: {
        rows: {
            type: Array,
            default: () => { return []; }
        },
        value: {
            type: Object,
            default: () => { return {}; }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFieldName(field) {
            // Return if field is an empty object
            if (!field || !Object.keys(field).length) {
                return;
            }

            if (field.attributes?.input?.find(attribute => attribute.attribute === 'dont-show')) {
                return 'hiddenField';
            }

            if (field.handle === 'editionSelect') {
                return 'editionSelectField';
            }

            return field.__typename ? `${field.__typename.replace('FreeformField_', '').toLowerCase()}Field` : `${field.type}Field`;
        },
        checkConditional(field) {
            // Return if field is an empty object
            if (!field || !Object.keys(field).length) {
                return false;
            }
            const foundAttribute = field.attributes?.input?.find((attribute) => {
                return attribute.attribute === 'conditional';
            });

            if (!foundAttribute) {
                return true;
            }

            const [handle, value] = foundAttribute.value.split(':');

            const foundField = flatMap(this.rows, row => row.fields || []).find(rowField => rowField.handle === handle);
            if (foundField.type === 'checkbox_group' || foundField.type === 'radio_group') {
                const foundOption = foundField.options.find(option => option.value === value && option.checked === true);

                return !!foundOption;
            }

            return true;
        }
    }
};
