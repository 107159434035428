
export default {
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            uploadedFile: false
        };
    },
    methods: {
        change(event) {
            if (event.srcElement.files && event.srcElement.files.length > 0) {
                this.uploadedFile = event.srcElement.files[0];
            } else {
                this.uploadedFile = false;
            }
            this.$emit('change', this.uploadedFile);
        }
    }
};
