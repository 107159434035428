
export default {
    props: {
        field: {
            type: Object,
            required: true
        },
        columns: {
            type: Number,
            required: true
        }
    }
};
