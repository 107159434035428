
import { ValidationProvider } from 'vee-validate';
import DnCheckbox from '~/patterns/atoms/checkbox/checkbox';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        DnCheckbox,
        FormField
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    computed: {
        rules() {
            return this.field.required ? 'required' : null;
        }
    },
    watch: {
        'field.options': {
            handler() {
                this.$emit('change', this.field.options);
            },
            deep: true
        }
    }
};
