
import GwMultiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwLabel from '~/patterns/atoms/label/label.vue';

export default {
    components: {
        GwMultiselect,
        GwIcon,
        GwLabel
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            value: ''
        };
    },
    watch: {
        value(val) {
            this.$emit('change', val);
        }
    }
};
